import { createRouter, createWebHistory } from 'vue-router'
import * as Auth from '@/auth'
import axios from 'axios'
import * as Lang from '../lang/lang'
const $Lang = Lang

function loadViews (view) {
    return () => import('../' + view + '.vue')
}

const routes = [
    /* System Views */
        { path: '/', name: 'Login', component: loadViews('views/Login'), meta:{ requiresAuth:false, field:'login' } },
        { path: '/questionnaire/', name:'Questionnaire', component: loadViews('views/Questionnaire'), meta:{ requiresAuth:true, field:'questionnaire' }},
        { path: '/course/', name:'Course', component: loadViews('views/Course'), meta:{ requiresAuth:true, field:'course' }},
        { path: '/lesson/:id/', name:'Lesson', component: loadViews('views/Lesson'), meta:{ requiresAuth:true, field:'lesson' }},
        { path: '/interview/', name:'Events', component: loadViews('views/Interview-List'), meta:{ requiresAuth:true, field:'interview' }},
        { path: '/interview-detail/:id/', name:'Events Detail', component: loadViews('views/Interview-Detail'), meta:{ requiresAuth:true, field:'interview-detail' }},
        { path: '/other/', name:'Other Information', component: loadViews('views/Other-List'), meta:{ requiresAuth:true, field:'other-information' }},
        { path: '/other-detail/:id/', name:'Other Information Detail', component: loadViews('views/Other-Detail'), meta:{ requiresAuth:true, field:'other-information-detail' }},
        { path: '/profile/', name:'Profile', component: loadViews('views/Profile'), meta:{ requiresAuth:true, field:'profile' }},
        { path: '/change-password/', name:'Change Password', component: loadViews('views/ChangePassword'), meta:{ requiresAuth:true, field:'change-password' }},
        { path: '/edit-questionnaire/', name:'Edit Questionnaire', component: loadViews('views/EditQuestionnaire'), meta:{ requiresAuth:true, field:'edit-questionnaire' }},
        { path: '/logout/', name: 'Logout', component: loadViews('views/Logout'), meta:{ field:'logout' }},
        
        
        { path: '/:pathMatch(.*)*', name: 'Login', component: loadViews('views/Login'), meta:{ requiresAuth:false, field:'login' }}
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    document.title = $Lang.get('document-title')

    if (to.fullPath !== '/logout/' && to.fullPath !== '/' && !Auth.isLogin())
    {
        let token = Auth.getLocalToken()
        if (token !== false) {
            const params = new FormData()
            params.append('token', token)
            axios
                .post(process.env.VUE_APP_API + 'check-login' + process.env.VUE_APP_SERVER_SIDE_LANG, params)
                .then(response => {
                    let r = response.data
                    if (r.success === true)
                    {
                        if (r.token) {
                            Auth.cacheLogin(r.token)

                            next()
                        } else {
                            next({ path:'/' })
                        }
                    } else {
                        next({ path:'/' })
                    }
                })
        } else {
            next({ path: '/' })
        }
    } else {
        if (to.fullPath !== '/' && to.fullPath !== '/questionnaire/' && Auth.isLogin() && to.fullPath !== '/logout/') {
            const params = new FormData()
            params.append('token', Auth.getToken())
            axios
                .post(process.env.VUE_APP_API + 'check-questionnaire' + process.env.VUE_APP_SERVER_SIDE_LANG, params)
                .then(response => {
                    let r = response.data
                    if (r.success === true) {
                        next()
                    } else {
                        next({ path: '/questionnaire/' })
                    }
                })
        } else {
            if (to.meta.requiresAuth && !Auth.isLogin()) {
                next({ path: '/' })
            } else {
                next()
            }
        }
    }
})

export default router


<template>
  <div id="app" ref="app">

    <div :class="['page-wrapper', (inner) ? 'inner' : '' ]">
        <div :class="['logo', (hide)?'hide':'']">
            <div class="top-background"></div>
            <div class="logo-character-small" @click="home"><img src="~@/assets/logo-character-small.png" /></div>
            <div class="logo-name" @click="home"><img src="~@/assets/logo-name.png" /></div>
            <div class="logo-character"><img src="~@/assets/logo-character.png" /></div>
            <div class="star left-1"><img src="~@/assets/logo-star.png" /></div>
            <div class="star left-2"><img src="~@/assets/logo-star.png" /></div>
            <div class="star left-3"><img src="~@/assets/logo-star.png" /></div>
            <div class="star right-1"><img src="~@/assets/logo-star.png" /></div>
            <div class="star right-2"><img src="~@/assets/logo-star.png" /></div>
            <div class="star right-3"><img src="~@/assets/logo-star.png" /></div>
        </div>
        <div class="wrapper">
            <div :class="['logo-container',(hide)?'hide':'']">
              <div class="logo-character-overlay"><img src="~@/assets/logo-character-overlay.png" /></div>
              <div class="background-container">
                  <div class="bottom-background"></div>
                  <div class="background-wrapper"></div>
              </div>
            </div>
            <Header v-if="ready && inner" ref="header" />
            <div class="content-wrapper">
              <div class="view" v-if="ready">
                <router-view v-slot="{ Component }" :key="$route.fullPath" @swapInner="animateInner" >
                  <component :is="Component" />
                </router-view>
              </div>
              <Loader v-if="!ready" />
              <Footer v-if="ready && inner" ref="footer" />
            </div>
        </div>
        <!--
        <div v-if="Auth.isLogin() && !installedPWA" class="pwa-installer">
            <div class="message">
                <div class="icon"><img src="~@/assets/logo-character-small.png" /></div>
                安裝「梓峰教育」應用程式
            </div>
            <a class="button" @click="installPWA">立即安裝</a>
            <a class="button" @click="cancelInstallPWA">稍後安裝</a>
        </div>
      -->
    </div>
  </div>
</template>

<script>
import Loader from './Loader'
import Header from './views/Header'
import Footer from './views/Footer'
export default {
    components: { Loader, Header, Footer },
    data() {
        return {
            inner: false,
            scrollY: 0,
            ready: false,
            hide: false,
            installedPWA: true
        }
    },
    beforeMount() {
      /*
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault()
            this.installEvent = e
        })
        */
    },
    mounted() {
        if (this.Auth.isLogin()) {
            setTimeout(this.animateInner, 1500)
        } else {
            this.ready = true
        
            let token = this.Auth.getLocalToken()
            if (token !== false) {
                const params = new FormData()
                params.append('token', token)
                this.$axios
                    .post(process.env.VUE_APP_API + 'check-login' + process.env.VUE_APP_SERVER_SIDE_LANG, params)
                    .then(response => {
                        let r = response.data
                        if (r.success === true)
                        {
                            if (r.token) {
                                this.Auth.cacheLogin(r.token)
                                this.animateInner()
                            }
                        }
                    })
            }
        }
        
        /*
        let choice = localStorage.getItem(process.env.VUE_APP_SESSION_NAME + 'pwa-installed')
        if (choice !== 'installed') {
            window.console.log(choice)
            if (choice == 'cancel') {
                let cancel_date = localStorage.getItem(process.env.VUE_APP_SESSION_NAME + 'pwa-installed-cancel-date')
                let date = new Date(cancel_date)
                date.setDate(date.getDate()  + 7)
                let now = new Date()
                if (now.getTime() > date.getTime()) {
                    this.installedPWA = false
                }
            } else {
                this.installedPWA = false
            }
        }
        */
    },
    methods: {
        showPWA() {
            let choice = localStorage.getItem(process.env.VUE_APP_SESSION_NAME + 'pwa-installed')
            if (choice != 'installed') {
                this.installedPWA = false
            }
        },
        installPWA() {
            if(this.installEvent) {
                this.installEvent.prompt()
                this.installEvent.userChoice.then((choice) => {
                    if (choice.outcome == 'accepted') {
                        localStorage.setItem(process.env.VUE_APP_SESSION_NAME + 'pwa-installed', 'installed')
                        this.installedPWA = true
                    }
                })
            } else {
                localStorage.setItem(process.env.VUE_APP_SESSION_NAME + 'pwa-installed', 'installed')
                this.installedPWA = true
            }
        },
        cancelInstallPWA() {
            localStorage.setItem(process.env.VUE_APP_SESSION_NAME + 'pwa-installed', 'cancel')
            localStorage.setItem(process.env.VUE_APP_SESSION_NAME + 'pwa-installed-cancel-date', new Date())
            this.installedPWA = true
        },
        animateInner () {
            this.ready = true
            this.inner = true
        },
        hideLogo() {
            this.hide = true
        },
        showLogo() {
            this.hide = false
        },
        home() {
            if (!this.inner) {
                this.$router.push('/')
            } else {
                this.$router.push('/course/')
            }
        },
        updateNotification() {
            this.$refs['header'].updateNotification()
            this.$refs['footer'].updateNotification()
        },
        setQuestionnaire() {
            this.$refs['header'].setQuestionnaire()
            console.log('set questionnaire')
        },
        setCompleteQuestionnaire() {
            console.log('complete questionnaire')
            this.$refs['header'].setCompleteQuestionnaire()
        },
        getQuestionnaire() {
            return this.$refs['header'].isQuestionnaire
        }
    }
}
</script>

<style lang="scss">
@import 'Style.scss';
@import 'pwa-installer.scss';

:root {
    --header-font-size:14px;
    --header-menu-bar-background-color:#614d64;
    --header-menu-bar-background-hover-color:#533e56;
    --header-menu-background-color:#4d3850;
    --header-menu-text-color:#ffffff;
    --header-menu-active-color:#614d64;
    --header-menu-over-color:#533e56;
    --header-submenu-background-color:#dedede;
    --header-submenu-background-color-hover:#eeeeee;
    --header-submenu-text-color:#333333;
    --header-submenu-text-color-hover:#ffffff;

    --modal-title-background-color:#614d64;
    --modal-title-text-color:#ffffff;
    --modal-background-color:#ffffff;
    --modal-text-color:#333333;

    /* Color */
      --base-color-light:#614d64;
      --base-text-color:#ffffff;
      --base-color-light-text:#ffffff;
      --base-color-dark:#442f47;
    
    /* Text */
      --text-color:#ffffff;

    /* Link */
      --link-color:#ffffff;
      --link-font-size:15px;

    /* Button */
      --button-link-background-color:#614d64;
      --button-link-background-color-hover:#99889b;
      --button-link-text-color:#ffffff;
      --button-link-font-size:16px;
      --button-link-font-size-mini:14px;
      --button-link-line-height:30px;
      --button-link-inactive-background-color:#cccccc;
      --button-link-inactive-text-color:#333333;

      --button-background-color:#614d64;
      --button-background-color-hover:#8a638f;
      --button-light-background-color:#99889b;
      --button-light-background-color-hover:#8a638f;
      --button-text-color:#ffffff;
      --button-font-size:16px;
      --button-line-height:30px;

    /* Layout */
      --layout-border-color:#cacaca;
      --layout-border-line-color:#614d64;

    /* Form */
      --form-group-background-color:#eeeeee;
      --form-group-title-background-color:#1b9aca;
      --form-group-title-text-color:#ffffff;
      --form-control-background-color:#7b667e;

    /* List */
      --list-table-title-background-color:#614d64;
      --list-table-title-background-color-hover:#7b667e;
      --list-table-title-text-color:#ffffff;
      --list-table-data-background-color:#eeeeee;
      --list-table-data-text-color:#333333;
      --list-border-color:#cccccc;
}

.error { color:#ffff00;}
.loader-image {
    margin:auto;
    content:'';
    display:inline-block;
    padding-bottom:10px;
    width:33px;
    height:31px;
    background:no-repeat center center;
    background-image:url('~@/assets/loader.gif');
    background-size:contain;
}

.view { min-height:calc(100vh - 400px); }
.page-wrapper {
    .logo-container {
      &.hide { display:none; }
    }
    .logo {
        z-index:1;
        position:relative;
        height:325px;
        background:#ffffff;
        width:100%;
        &.hide {
          display:none;
        }
        &:after {
            width:100%;
            height:210px;
            background:#ffffff;
            position:absolute;
            overflow:hidden;
            top:325px;
            left:0px;
            content:'';
            z-index:1;
        }
        .top-background {
          z-index:2;
          background:url('~@/assets/top-background.png') no-repeat center top;
          background-size:cover;
          width:100%;
          height:40vh;
          position:absolute;
          top:0px;
          left:0px;
        }

        .logo-character-small {
          position:absolute;
          top:20px;
          left:50%;
          user-select:none;
          img {
            height:63px;
            position:absolute;
            top:0px;
            left:-84px;
            opacity:0;
          }
        }
        .logo-name {
            position:absolute;
            top:50px;
            left:50%;
            cursor:pointer;
            img {
                position:absolute;
                top:0px;
                width:156px;
                left:-78px;
                animation:logo-name-preset 2s;
                animation-fill-mode:forwards;
            }
        }
        .logo-character {
            position:absolute;
            bottom:-93px;
            z-index:2;
            left:50%;
            user-select:none;
            img {
                position:absolute;
                bottom:0px;
                left:-135px;
                animation:logo-character 1s ease-out;
                animation-fill-mode:forwards;
            }
        }

        .star {
          display:none;
          position:absolute;
          bottom:-93px;
          left:50%;
          img {
            position:absolute;
          }

          &.left-1 { 
            img {
              animation:star-left-1 0.25s;
              animation-fill-mode: forwards;
              animation-delay:0.5s;
              opacity:0;
              left:-108px;
              bottom:258px;
            }
          }
          &.left-2 {
            img {
              animation:star-left-2 0.25s;
              animation-fill-mode: forwards;
              animation-delay:0.25s;
              opacity:0;
              left:-144px;
              bottom:206px;
            }
          }
          &.left-3 {
            img {
              animation:star-left-3 0.25s;
              animation-fill-mode: forwards;
              opacity:0;
              left:-157px;
              bottom:143px;
            }
          }
          &.right-1 {
            img {
              animation:star-right-1 0.25s;
              animation-fill-mode: forwards;
              animation-delay:1.25s;
              opacity:0;
              left:74px;
              bottom:258px;
            }
          }
          &.right-2 {
            img {
              animation:star-right-2 0.25s;
              animation-fill-mode: forwards;
              animation-delay:1s;
              opacity:0;
              left:115px;
              bottom:206px;
            }
          }
          &.right-3 {
            img {
              animation:star-right-3 0.25s;
              animation-fill-mode: forwards;
              animation-delay:0.75s;
              opacity:0;
              left:129px;
              bottom:143px;
            }
          }
        }
    }
    .wrapper {
        z-index:2;
        position:relative;
        // overflow:hidden;
        min-height:calc(100vh - 325px);
        overflow:hidden;

        .bottom-background {
          position:absolute;
          top:0px;
          left:-100vw;
          width:300vw;
          height:300vw;
          border-radius:150vw;
          background:url('~@/assets/bottom-background.png') no-repeat center top;
          z-index:2;
        }
        .logo-character-overlay {
            z-index:3;
            position:absolute;
            left:calc(50vw + 25px);
            top:-10px;
            user-select:none;
            animation:logo-character-overlay 1s ease-out;
            animation-fill-mode:forwards;
        }
        .background-container {
            width:100%;
            height:calc(100vh - 100px);
            min-height:50vh;
            overflow:hidden;
            position:absolute;
            .background-wrapper {
                background:#1b9aca;
                z-index:1;
                border:10px solid #25abde;
                box-shadow:9px 40px 40px inset rgba(0,0,0,0.1);
                position:absolute;
                height:300vw;
                width:300vw;
                left:-100vw;
                border-radius:150vw;
            }
        }
        .content-wrapper {
            position:relative;
            z-index:2;
        }
        .copyright {
            padding-bottom:20px;
            font-size:9px;
            text-align:center;
            text-shadow:1px 1px 1px rgba(0,0,0,0.44);
        }
    }

  &.inner {
    .logo {
      animation:logo 1s ease-out;
      animation-fill-mode:forwards;
      animation-delay:0.5s;
      &:after {
        animation:logo-after 1s ease-out;
        animation-fill-mode:forwards;
        animation-delay:0.5s;
      }

      .logo-character-small {
        cursor:pointer;
        z-index:10;
        img {
          animation:logo-character-small 0.5s ease-out;
          animation-fill-mode:forwards;
          animation-delay:2s;
        }
      }
      .logo-name {
        top:30px;
        transition:top 1s ease-out;
        cursor:pointer;
        z-index:10;
        img {
          position:absolute;
          animation:logo-name-exit 2s ease-out;
          animation-fill-mode:forwards;
        }
      }
      .logo-character {
        img {
          animation:logo-character-exit 1s ease-out;
          animation-fill-mode:forwards;
        }
      }
      .star {
        animation:star 0.5s ease-out;
        animation-fill-mode:forwards;
      }
    }

     .wrapper {
       .logo-character-overlay {
          animation:logo-character-overlay-exit 1s ease-out;
          animation-fill-mode:forwards;
       }

        .bottom-background {
          animation:background-wrapper 1s ease-out;
          animation-fill-mode:forwards;
          animation-delay:0.5s;
        }
       .background-container {
         .background-wrapper {
           animation:background-wrapper 1s ease-out;
           animation-fill-mode:forwards;
           animation-delay:0.5s;
         }
       }
     }
  }

}

@keyframes star {
  100% { opacity:0; }
}
@keyframes logo {
  100% {
    height:100px;
  }
}
@keyframes logo-after {
  100% {
    top:100px;
    height:300px;
  }
}

@keyframes background-wrapper {
  100% {
    height:500vw;
    width:500vw;
    left:-200vw;
    border-radius:250vw;
  }
}

@keyframes logo-character-small {
  0% { left:-60px; opacity:0; }
  100% { left:-84px; opacity:1; }
}

@keyframes logo-name-preset {
  0% { opacity:0; }
  100% { opacity:1; }
}
@keyframes logo-name-exit {
  20% { left:-52px; width:104px; }
  80% { left:-52px; width:104px; }
  100% { left:-20px; width:104px; }
}

@keyframes logo-character {
  0% { transform:translateY(100px); }
  50% { transform:translateY(-40px); }
  100% {}
}
@keyframes logo-character-exit {
  30% { transform:translateY(-40px); opacity:1; }
  100% { transform:translateY(200px); opacity:0; }
}

@keyframes logo-character-overlay {
  0% { transform:translateY(100px); opacity:0; }
  50% { transform:translateY(-40px); opacity:0; }
  100% {}
}
@keyframes logo-character-overlay-exit {
  30% { transform:translateY(-40px); opacity:0; }
  100% { transform:translateY(200px); opacity:0; }
}


@keyframes star-left-1 {
  0% { left:-144px; bottom:206px; opacity:0; transform:scale(20); }
  100% { opacity:1; transform:scale(1); }
}

@keyframes star-left-2 {
  0% { left:-157px; bottom:143px; transform:rotate(-30deg) scale(20); opacity:0; }
  100% { transform:rotate(0deg) scale(1); opacity:1; }
}

@keyframes star-left-3 {
  0% { transform:rotate(-45deg) translateX(0px) translateY(300px) scale(20); opacity:0; }
  100% { transform:rotate(0deg) translateY(0px) scale(1); opacity:1; }
}

@keyframes star-right-1 {
  0% { left:115px; bottom:206px; opacity:0; transform:scale(20); }
  100% { opacity:1; transform:scale(1); }
}

@keyframes star-right-2 {
  0% { left:129px; bottom:143px; opacity:0; transform:scale(20); }
  100% { opacity:1; transform:scale(1); }
}

@keyframes star-right-3 {
  0% { transform:translateX(0px) translateY(200px) scale(20); opacity:0; }
  100% { transform:translateY(0px) scale(1); opacity:1; }
}

</style>

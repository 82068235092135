import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import * as Auth from './auth'
import md5 from 'js-md5'
import * as Lang from './lang/lang'
//import './registerServiceWorker'

const app = createApp(App)

app.config.globalProperties.$axios = axios
app.config.globalProperties.Auth = Auth
app.config.globalProperties.$md5 = md5

let $Lang = Lang
app.config.globalProperties.$Lang = $Lang
app.config.globalProperties.$Lang.setLang( 'tc' )

app.use(router)
app.mount('#app')

import en from './locale/en.json'
import tc from './locale/tc.json'
export var locale = 'zh'
export const get = function (field) {
    let data
    switch (locale) {
        case 'en' :
            data = en
            break
        case 'tc' :
            data = tc
            break
    }
    if(data[field]) return data[field]
    return 'Undefined'
}

export const getTitle = function (field) {
    if (field !== undefined) {
        if (field.toString().indexOf('{') == 0) {
            let f = JSON.parse(field)
            if (f[locale] !== undefined) return f[locale]
        }
        if (typeof field === 'object') {
            if (field[locale] !== undefined) return field[locale]
        }
    }
    return field
}

export const setLang = function (lang) {
    locale = lang
}

export const getLang = function () {
    return locale
}

export const getLanguageOptions = function () {
    return [
        { id:'tc', title:'繁體中文' },
        { id:'en', title:'English' }
    ]
}

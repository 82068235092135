export default {
    methods: {
        getLimitDate(type) {
            let d = null
            if (typeof type === 'string') {
                switch (type)
                {
                    case 'today' :
                        d = new Date()
                        break
                }
            } else if (typeof type === 'object') {
                for(let i in type)
                {
                    switch (i)
                    {
                        case 'year' :
                            d = new Date()
                            d.setYear(d.getFullYear() + type[i])
                            break
                    }
                }
            }
            return d
        },
        getLastUpdateDate(date) {
            let d = new Date(date.replace(/\s/, 'T'))
            if (d) {
                return d.getFullYear() + ' 年 ' + (d.getMonth() + 1) + ' 月 ' + d.getDate() + ' 日'
            }
        },
        getDateFormat(date) {
            let d = new Date(date)
            if (d) {
                return d.getFullYear() + ' 年 ' + (d.getMonth() + 1) + ' 月 ' + d.getDate() + ' 日'
            }
        },
        getYear() {
            let date = new Date()
            return date.getFullYear()
        },
        checkPasswordStrength (password) {
            if (password === '') return ''
            
            let verystrong = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{16,})')
            let strong = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
            let medium = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})')
            
            if (verystrong.test(password)) {
                return 'very-strong'
            } else if (strong.test(password)) {
                return 'strong'
            } else if (medium.test(password)) {
                return 'medium'
            }

            return 'weak'
        },
        randomPassword () {
            let p = Array(16).fill("!@#$%^&()0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz").map(function(x) { return x[Math.floor(Math.random() * x.length)] }).join('')
            if (this.checkPasswordStrength(p) !== 'very-strong') {
                return this.randomPassword()
            }
            return p
        },
        validEmailAddress (email) {
            const re = /^\w+([-+.'][^\s]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
            return re.test(email)
        }        
    }
}

<template>
    <footer>
        <div class="ground-wrapper">
            <div class="ground"></div>
            <router-link to="/course/" class="item-black-block">
                <div class="title">課堂支援</div>
                <div class="class">小一面試班</div>
            </router-link>
            <router-link to="/interview/" class="item events">
                <label>裝備面試系列</label>
                <div class="notify" v-if="event !== '' && event > 0">{{ event }}</div>
            </router-link>
            <div class="item-character"></div>
            <router-link to="/other/" class="item other">
                <label>其他資訊</label>
                <div class="notify" v-if="other !== '' && other > 0">{{ other }}</div>
            </router-link>
            <div class="glass"></div>
        </div>
        <div class="copyright">{{ $Lang.get('copyright') }} {{ getYear() }} {{ $Lang.get('copyright-title') }}</div>
    </footer>
</template>


<script>
import Fn from './Fn'
export default {
    mixins:[ Fn ],
    mounted() {
        this.updateNotification()
    },
    data() {
        return {
            event: '',
            other: ''
        }
    },
    methods: {
        updateNotification() {
            const params = new FormData()
            params.append('token', this.Auth.getToken())
            this.$axios
                .post(process.env.VUE_APP_API + 'get-notification' + process.env.VUE_APP_SERVER_SIDE_LANG, params)
                .then(response => {
                    let r = response.data
                    if (r.success === true){
                        let data = r.data
                        if (data.event) {
                            this.event = parseInt(data.event)
                            if (this.event > 99) this.event = '99+'
                        }
                        if (data.other) {
                            this.other = parseInt(data.other)
                            if (this.other > 99) this.other = '99+'
                        }
                    }
                })
        }
    }
}
</script>

<style scoped lang="scss">
@import 'Page.scss';
footer {
    height:300px;
    width:100%;
    overflow:hidden;
    position:relative;
    
    .ground-wrapper {
        position:absolute;
        left:50%;
        bottom:0px;
        height:160px;
        width:100%;
        .ground {
            width:800vw;
            height:800vw;
            border-radius:400vw;
            background:#a2e33b;
            position:absolute;
            left:-400vw;
            top:0px;
        }
        a {
            .notify {
                position:absolute;
                top:0px;
                right:20px;
                width:24px;
                height:24px;
                background:#ff0000;
                color:#ffffff;
                font-size:14px;
                z-index:10;
                border-radius:12px;
                line-height:24px;
                text-align:center;
            }
        }
    }

    .item {
        a { cursor:pointer; }
        label {
            font-size:28px;
            color:#eed7be;
            text-shadow:stroke(1px, #7d6347);
            position:absolute;
            top:0px;
            left:0px;
            pointer-events:none;
            white-space:nowrap;
        }

        &.events {
            background:url('~@/assets/item-events.png') no-repeat center center;
            background-size:contain;
            width:175px;
            height:111px;
            position:absolute;
            top:-55px;
            left:-360px;
            cursor:pointer;
            label {
                top:12px;
                left:-10px;
                transform:scaleX(0.7) skewY(-1deg);
            }
        }

        &.other {
            background:url('~@/assets/item-other.png') no-repeat center center;
            background-size:contain;
            width:175px;
            height:111px;
            position:absolute;
            top:-50px;
            left:110px;
            cursor:pointer;
            label {
                top:12px;
                left:17px;
                transform:skewY(-1deg);
            }
        }
    }

    .item-black-block {
        background:url('~@/assets/item-black-block.png') no-repeat center center;
        background-size:contain;
        position:absolute;
        top:-125px;
        left:-175px;
        width:201px;
        height:179px;
        cursor:pointer;
        .title {
            font-size:20px;
            text-shadow:none;
            position:absolute;
            top:25px;
            left:30px;
            letter-spacing:2px;
            transform:skew(0deg, -8deg) scaleY(1.2);
        }
        .class {
            font-size:20px;
            text-shadow:none;
            position:absolute;
            top:55px;
            left:60px;
            letter-spacing:2px;
            transform:skew(0deg, -8deg) scaleY(1.2);
        }
    }

    .item-character {
        background:url('~@/assets/item-character.png') no-repeat center center;
        background-size:contain;
        position:absolute;
        top:-130px;
        left:0px;
        width:189px;
        height:196px;
    }

    .glass {
        background:url('~@/assets/footer-glass.png') repeat-x left bottom;
        background-size:contain;
        width:100%;
        height:100px;
        left:-50%;
        position:absolute;
        bottom:0px;

    }

    .copyright {
        width:100%;
        height:10px;
        position:absolute;
        bottom:0px;
    }
}
@media only screen and (max-width:700px) {
    footer {
        .item {
            &.events {
                transform:scale(0.65);
                transform-origin:0 0;
                left:-180px;
                top:-40px;
            }
            &.other {
                transform:scale(0.65);
                transform-origin:0 0;
                left:80px;
                top:-40px;
            }
        }

        .item-black-block {
            transform:scale(0.65);
            transform-origin:0 0;
            left:-80px;
            top:-90px;
        }
        .item-character {
            transform:scale(0.65);
            transform-origin:0 0;
            top:-90px;
            left:35px;
        }
    }
}
</style>
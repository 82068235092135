<template>
    <div class="loader">
        <div class="loader-image" ref="loader-image"></div>
        <div>準備中...</div>
    </div>
</template>

<style scoped lang="scss">
.loader {
    padding-top:150px;
    text-align:center;
}
</style>

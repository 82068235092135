export const no_permission = "no-permission"

export const isLogin = function () {
  const s = sessionStorage[process.env.VUE_APP_SESSION_NAME]
  if (s !== undefined && s !== '') {
    return true
  }
  return false
}

export const cacheLogin = function (token) {
  sessionStorage.setItem(process.env.VUE_APP_SESSION_NAME, token)
  localStorage.setItem(process.env.VUE_APP_SESSION_NAME, token)
}

export const Logout = function() {
  sessionStorage.removeItem(process.env.VUE_APP_SESSION_NAME)
  localStorage.removeItem(process.env.VUE_APP_SESSION_NAME)
}

export const getLocalToken = function() {
  if (localStorage[process.env.VUE_APP_SESSION_NAME]) {
    return localStorage[process.env.VUE_APP_SESSION_NAME]
  }
  return false
}

export const getToken = function()
{
  return sessionStorage[process.env.VUE_APP_SESSION_NAME]
}


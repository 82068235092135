<template>
    <header>
        <template v-if="!isQuestionnaire">
            <div class="desktop">
                <router-link to="/profile/">學生帳號 <span class="icon-settings"></span></router-link>
            </div>
            <ul class="mobile-menu">
                <li>
                    <router-link to="/interview/">
                        <div class="icon events"></div>
                        <div class="name">裝備面試系列</div>
                        <div class="notify" v-if="event !== '' && event > 0">{{ event }}</div>
                    </router-link>
                </li>
                <li><router-link to="/course/"><div class="icon course"></div><div class="name">課堂支援</div></router-link></li>
                <li>
                    <router-link to="/other/">
                        <div class="icon other"></div>
                        <div class="name">其他資訊</div>
                        <div class="notify" v-if="other !== '' && other > 0">{{ other }}</div>
                    </router-link>
                </li>
                <li><router-link to="/profile/"><div class="icon settings"></div><div class="name">學生帳號</div></router-link></li>
            </ul>
        </template>
        <template v-else>
            <div class="desktop">
                <router-link to="/logout/">登出 <span class="icon-settings"></span></router-link>
            </div>
            <ul class="mobile-menu">
                <li>
                    <router-link to="/logout/">
                        <div class="icon settings"></div>
                        <div class="name">登出</div>
                    </router-link>
                </li>
            </ul>
        </template>
    </header>
</template>

<script>
export default {
    mounted() {
        this.updateNotification()
    },
    data() {
        return {
            event: '',
            other: '',
            isQuestionnaire: false
        }
    },
    methods: {
        updateNotification() {
            const params = new FormData()
            params.append('token', this.Auth.getToken())
            this.$axios
                .post(process.env.VUE_APP_API + 'get-notification' + process.env.VUE_APP_SERVER_SIDE_LANG, params)
                .then(response => {
                    let r = response.data
                    if (r.success === true){
                        let data = r.data
                        if (data.event) {
                            this.event = parseInt(data.event)
                            if (this.event > 99) this.event = '99+'
                        }
                        if (data.other) {
                            this.other = parseInt(data.other)
                            if (this.other > 99) this.other = '99+'
                        }
                    }
                })
        },
        setQuestionnaire() {
            this.isQuestionnaire = true
        },
        setCompleteQuestionnaire() {
            this.isQuestionnaire = false
        } 
    }
}
</script>

<style scoped lang="scss">
header {
  
    .desktop {
        position:fixed;
        top:10px;
        left:0px;
        width:100%;
        text-align:right;
        padding:15px 30px;
        box-sizing:border-box;
        vertical-align: middle;
        z-index:100;
        pointer-events:none;
        a {
            line-height:15px;
            color:#333333;
            text-shadow:none;
            background:rgba(255,255,255,0.7);
            padding:5px 10px;
            border-radius:4px;
            pointer-events:auto;
        }

        .icon-settings {
            background:url('~@/assets/icon-settings.png') no-repeat center center;
            background-size:contain;
            width:14px;
            height:14px;
            margin-top:2px;
            content:'';
            display:inline-block;
            vertical-align:top;
        }
    }

    .mobile-menu { display:none; }
}
@media only screen and (max-width:1024px) {
    header {

        .desktop { display:none; }
        .mobile-menu {
            display:flex;
            flex-direction:row;
            justify-content:space-evenly;
            position:fixed;
            bottom:0px;
            left:0px;
            width:100%;
            background:#ffffff;
            padding:15px 0px 15px 0px;
            box-sizing:border-box;
            height:90px;
            margin:0px;
            z-index:10000;
            box-shadow:0px 0px 13px rgba(0,0,0,0.3);
            li {
                display:inline-block;
                position:relative;
                .icon {
                    width:32px;
                    height:32px;
                    margin:0px auto 10px auto;
                    background:no-repeat center center;
                    background-size:contain;
                    &.events {
                        background-image:url('~@/assets/menu-icon-events.png');
                    }
                    &.course {
                        background-image:url('~@/assets/menu-icon-course.png');
                    }
                    &.other {
                        background-image:url('~@/assets/menu-icon-other.png');
                    }
                    &.settings {
                        background-image:url('~@/assets/menu-icon-settings.png');
                    }
                }
                a {
                    color:#333; text-shadow:none;
                    position:relative;
                    display:block;
                    .notify {
                        position:absolute;
                        top:-6px;
                        right:-15px;
                        width:24px;
                        height:24px;
                        text-align:center;
                        color:#ffffff;
                        font-size:13px;
                        line-height:24px;
                        background:#ff0000;
                        border-radius:12px;
                    }
                }
            }
        }
    }
}
</style>